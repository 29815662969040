<template>
    <el-drawer
        v-model="drawer"
        :direction="rtl"
        @closed="handleClose()"
        @opened="handleOpen()"
    >
        <el-scrollbar height="85vh">
            <el-form
                class="p-4 pt-0"
                label-position="top"
                label-width="100px"
                :model="fleet"
                ref="fleet"
                :rules="rules"
            >
                <el-form-item label="Usuario" prop="applicationUser">
                    <el-select class="w-75" v-model="fleet.applicationUserId">
                        <el-option
                            v-for="item in users"
                            :key="item.id"
                            :value="item.id"
                            :label="item.firstName + ' ' + item.lastName"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="Vehículo" prop="vehicleId">
                    <el-select class="w-75" v-model="fleet.vehicleId">
                        <el-option
                            v-for="item in vehicles"
                            :key="item.id"
                            :value="item.id"
                            :label="item.registration"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="Tanda" prop="workSessionId">
                    <el-select class="w-75" v-model="fleet.workSessionId">
                        <el-option
                            v-for="session in workSessions"
                            :key="session.id"
                            :value="session.id"
                            :label="session.name"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="Desde" prop="from">
                    <el-date-picker
                        class="w-75"
                        v-model="fleet.from"
                        type="date"
                        size="large"
                        placeholder="Seleccione una fecha"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="Hasta" prop="to">
                    <el-date-picker
                        class="w-75"
                        v-model="fleet.to"
                        type="date"
                        size="large"
                        placeholder="Seleccione una fecha"
                    >
                    </el-date-picker>
                </el-form-item>

                <div class="w-75">
                    <el-button @click="$emit('drawerclosed')" round
                        >Cancelar</el-button
                    >
                    <el-button
                        class="pull-end bg-yellow"
                        style="float: right"
                        round
                        @click="manageFleet()"
                        >Guardar</el-button
                    >
                </div>
                <el-divider></el-divider>
                <h3 class="font-large font-weight-bold font-italic">
                    Herramientas
                </h3>
                <el-button
                    type="default"
                    style="padding: 10px"
                    size="mini"
                    icon="el-icon-delete"
                    @click="handleDeleteAssigment"
                ></el-button>
            </el-form>
        </el-scrollbar>
    </el-drawer>
    <!-- test -->
</template>

<script>
import { defineComponent } from "vue";
import fleetService from "@/services/fleetAssigmentService";
import lookupService from "@/services/lookoutService";
import {
    ElDrawer,
    ElButton,
    ElForm,
    ElScrollbar,
    ElSelect,
    ElDatePicker,
    ElOption,
    ElDivider,
    ElMessageBox,
    ElMessage,
} from "element-plus";
export default defineComponent({
    components: {
        ElDrawer,
        ElButton,
        ElForm,
        ElScrollbar,
        ElSelect,
        ElDatePicker,
        ElOption,
        ElDivider,
    },
    data() {
        return {
            drawer: false,
            fleet: {},
            workSessions: [],
            vehicles: [],
            users: [],
            rules: {
                vehicleId: [
                    {
                        required: true,
                        message: "Este campo es requerido",
                        trigger: "blur",
                    },
                ],
                applicationUser: [
                    {
                        required: true,
                        message: "Este campo es requerido",
                        trigger: "blur",
                    },
                ],
                workSessionId: [
                    {
                        required: true,
                        message: "Este campo es requerido",
                        trigger: "blur",
                    },
                ],
                from: [
                    {
                        required: true,
                        message: "Este campo es requerido",
                        trigger: "blur",
                    },
                ],
                to: [
                    {
                        required: true,
                        message: "Este campo es requerido",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    props: {
        editMode: {
            type: Boolean,
            default: false,
        },
        isActive: {
            type: Boolean,
            default: false,
        },
        fleetId: {
            type: String,
            default: null,
        },
    },
    watch: {
        isActive(value) {
            this.drawer = value;
        },
    },
    methods: {
        handleClose() {
            this.$emit("drawerclosed");
            this.$refs["fleet"].resetFields();
        },
        async handleDeleteAssigment() {
            ElMessageBox.confirm(
                `¿Deseas borrar este registro permanentemente?`,
                {
                    confirmButtonText: "Borrar",
                    cancelButtonText: "Cancelar",
                    type: "danger",
                    confirmButtonClass: "bg-danger",
                }
            )
                .then(async () => {
                    await this.deleteAssigment(this.fleet.id);
                })
                .catch(() => {
                    ElMessage({
                        type: "info",
                        message: "Acción cancelada",
                    });
                });
        },
        async deleteAssigment(id) {
            await fleetService
                .deleteAssigment(id)
                .then(() => {
                    ElMessage({
                        type: "success",
                        message: "Asignación eliminada exitosamente",
                    });
                    this.$emit("refreshData");
                })
                .catch(() => {
                    ElMessage({
                        type: "danger",
                        message: "Ha ocurrido un error inesperado.",
                    });
                });
        },
        async getVehicles() {
            const data = await fleetService.getVehicles();
            this.vehicles = data.data.items;
        },
        async getUsers() {
            const data = await fleetService.getUsers();
            this.users = data.data.items;
        },
        async getWorkSessions() {
            const data = await lookupService.getWorkSessions();
            this.workSessions = data.data;
        },
        async manageFleet() {
            let that = this;
            this.$refs["fleet"].validate(async (valid) => {
                if (valid) {
                    if (that.editMode) {
                        await that
                            .updateAssigment(this.fleet)
                            .then(() => {
                                ElMessage({
                                    type: "success",
                                    message:
                                        "Asignación actualizada exitosamente",
                                });
                                this.$emit("refreshData");
                            })
                            .catch(() => {
                                ElMessage({
                                    type: "danger",
                                    message: "Ha ocurrido un error inesperado.",
                                });
                            });
                    } else {
                        await that
                            .addAssigment(this.fleet)
                            .then(() => {
                                ElMessage({
                                    type: "success",
                                    message: "Asignación agregada exitosamente",
                                });
                                this.$emit("refreshData");
                            })
                            .catch(() => {
                                ElMessage({
                                    type: "danger",
                                    message: "Ha ocurrido un error inesperado.",
                                });
                            });
                    }
                } else {
                    return false;
                }
            });
        },
        async addAssigment(fleet) {
            let payload = {
                vehicleId: fleet.vehicleId,
                applicationUserId: fleet.applicationUserId,
                workSessionId: fleet.workSessionId,
                from: fleet.from,
                to: fleet.to,
            };
            await fleetService.addAssigment(payload);
            this.$emit("refreshData");
        },
        async updateAssigment(fleet) {
            await fleetService.updateAssigment(fleet);
            this.$emit("refreshData");
        },
        async handleOpen() {
            if (this.editMode) {
                var data = await fleetService.getAssigment({
                    id: this.fleetId,
                });
                this.fleet = data.data;
                this.fleet.id = this.fleetId;
            } else {
                this.fleet = [];
            }
        },
    },
    async mounted() {
        await this.getVehicles();
        await this.getWorkSessions();
        await this.getUsers();
    },
});
</script>
<style lang="scss">
.el-form-item__label {
    font-weight: bold !important;
}
.el-icon {
    --font-size: 31px;
}
</style>
