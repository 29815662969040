import { api } from "../core/api";

export default {
    getAssigments(params) {
        return api.get("/FleetAssigment/GetAll", { params: params });
    },
    getAssigment(param) {
        return api.get("/FleetAssigment/Get", { params: param });
    },
    addAssigment(fleet) {
        console.log(fleet);
        return api.post("/FleetAssigment/Add", fleet);
    },
    updateAssigment(fleet) {
        return api.patch("/FleetAssigment/Update", fleet);
    },
    toogleActivate(id) {
        return api.put(`/FleetAssigment/ToogleActivate?id=${id}`);
    },
    deleteAssigment(id) {
        return api.delete(`/FleetAssigment/Delete?id=${id}`);
    },
    getVehicles() {
        return api.get(`/Vehicle/GetAll?PageSize=100&PageIndex=1`);
    },
    getUsers() {
        return api.get(
            `/ApplicationUser/GetAll?PageSize=1000&PageIndex=1&Role=Delivery`
        );
    },
};
