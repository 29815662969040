<template>
    <div>
        <div class="w-100">
            <Header />
        </div>

        <div class="w-100 d-flex flex-row flex-wrap">
            <left-side-bar />
            <div class="ml-2" style="width: 90vw">
                <div
                    class="
                        d-flex
                        flex-row flex-wrap
                        justify-between
                        align-items-center
                        w-100
                    "
                >
                    <h1 class="font-x-large mt-2 font-weight-bold">
                        Asignaciones de flotas vehiculares
                    </h1>
                    <el-button
                        class="pull-end bg-yellow font-12 font-weight-bold"
                        style="float: right"
                        round
                        @click="$emit('newFleet')"
                    >
                        NUEVA ASIGNACIÓN
                    </el-button>
                    <el-divider></el-divider>
                </div>
                <div>
                    <tab-fleet-list @editFleet="rowClicked" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import Header from "@/components/dashboard/include/Header";
import leftSideBar from "@/components/dashboard/menu/leftSideBar";
import moment from "moment";
import "moment/locale/es-do";
import _service from "@/services/fleetAssigmentService";
import TabFleetList from "./tabFleetList.vue";
import { ElDivider } from "element-plus";
export default defineComponent({
    name: "Fleets",
    components: { Header, TabFleetList, leftSideBar, ElDivider },

    data() {
        return {
            tabRole: "",
            searchByquery: "",
            drawer: ref(false),
            activeName: "first",
            page_size: 10,
            page_index: 1,
            total_count: 23,
            total_pages: 10,
            tableData: [],
            multipleSelection: [],
        };
    },
    created() {
        this.getAllFleets(10, 1, "Delivery", this.searchByquery);
        this.moment = moment;
    },
    mounted() {
        this.moment.locale("es-do");
    },
    methods: {
        handleSizeChange(val) {
            console.log(`${val} items per page`);
        },
        onTabChanged(name) {
            this.tabRole = name.props.label;
        },
        handleCurrentChange(val) {
            const data = {
                PageSize: this.page_size,
                PageIndex: val,
            };
            _service.getAssigments(data).then((fleet) => {
                this.tableData = fleet.data.items;
            });
        },
        rowClicked(row) {
            this.$emit("editFleet", row);
        },
        toggleSelection(rows) {
            if (rows) {
                rows.forEach((row) => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        searchByQuery() {
            const data = {
                Query: this.searchByquery,
                PageSize: this.page_size,
                PageIndex: this.page_index,
            };

            _service.getAssigments(data).then((fleet) => {
                this.tableData = fleet.data.items;
            });
        },
        getAllFleets(page_size, page_index) {
            const data = {
                PageSize: page_size,
                PageIndex: page_index,
            };
            _service.getAssigments(data).then((data) => {
                this.total_pages = data.data.totalPages;
                this.total_count = data.data.pageSize;
                this.tableData = data.data.items;
            });
        },
    },
});
</script>
<style scoped>
.color-icon {
    color: white;
}
</style>
