<template>
    <div class="home">
        <fleet-list
            @newFleet="addNewFleet()"
            :key="fleetComponentKey"
            @editFleet="editFleet"
        />
        <manage-fleet
            :edit-mode="editMode"
            :is-active="drawer"
            :fleet-id="fleetId"
            @drawerclosed="drawer = false"
            @refreshData="refreshData"
        />
    </div>
</template>
<script>
// @ is an alias to /src
// import api from "../services/fleetService";
import manageFleet from "./components/manageFleet.vue";
import fleetList from "./components/fleetList.vue";

export default {
    name: "Home",
    data() {
        return {
            drawer: false,
            editMode: false,
            fleetId: null,
            fleetComponentKey: 0,
        };
    },
    components: {
        manageFleet,
        fleetList,
    },
    methods: {
        addNewFleet() {
            this.editMode = false;
            this.fleetId = null;
            this.drawer = true;
        },
        refreshData() {
            this.fleetComponentKey += 1;
            this.drawer = false;
        },
        editFleet(id) {
            this.editMode = true;
            this.fleetId = id;
            this.drawer = true;
        },
    },
    async mounted() {
        // let fleets = await api.getFleets({
        //     pageSize: 1,
        //     pageIndex: 1,
        //     role: "delivery",
        // });
    },
};
</script>
